<template>
<div class="tw-my-4">
  <ml-label>Documents</ml-label>
  <div class="tw-text-left" v-if="item.contracts && item.contracts.length > 0">
    <div v-for="(contract,index) in item.contracts" :key="index" class="tw-flex tw-my-2 tw-flow-root tw-justify-center" >

      <a :href="contract.src" download target="_blank" class="tw-float-left tw-text-xs tw-text-black tw-flex tw-items-center tw-justify-center tw-ml-2">
        <file-text-icon class="tw-w-6 tw-h-6 tw-text-gray-n2" />
        <span class="tw-ml-2">{{contract.name}}</span>
      </a>
      <span class="tw-ml-1 tw-text-xs tw-text-gray-n2 tw-flex tw-items-center tw-float-left tw-justify-center">
       | {{dateFormat(contract.created_at)}}
      </span>
      <a v-if="user.vendor" href="#" @click.prevent="destroyImage(contract.id)"
         class="tw-right-2 tw-top-2 tw-h-4 tw-w-4 tw-bg-white tw-rounded-full tw-text-red tw-flex tw-float-right tw-justify-center">
        <trash-2-icon  class="tw-h-6 tw-w-6" />
      </a>
    </div>

      <!--
      <gallery v-if="item.contracts && item.contracts.length > 0"
               class="tw-mt-3"
               :images="item.contracts"
               :gallery-setup="{}"
               :default-image-class="'tw-h-28'"
               :thumb-only="false"
               :limit="item.contracts.length"
               :thumb-wrapper-class="'tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-3'"
               :show-border="false"
      >
        <template v-slot:image-action="{image}">
          <a href="#" @click.prevent="openFile(image)"
             class="tw-right-2 tw-top-2 tw-h-12 tw-w-12 tw-bg-white tw-rounded-full tw-absolute tw-text-black tw-flex tw-items-center tw-justify-center">
            <file-text-icon class="tw-h-3 tw-w-3" />
          </a>
        </template>
      </gallery>
      <div v-else>
        <image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/>
      </div>
      -->

  </div>

  <v-file-input
      class="tw-my-4"
      v-if="user.vendor"
      @change="onFileSelected"
      accept=".pdf,.doc,.docx"
      :placeholder="$t('vendor.pick_contract')"
      prepend-icon="mdi-file"
      :label="$t('vendor.contract_file_size')"
      v-model="file"
  ></v-file-input>

  <v-progress-linear
      v-show="imageUpload"
      indeterminate
      color="red"
      class="mb-0"
  ></v-progress-linear>

  <v-alert
      v-if="imageUploadError"
      color="orange"
      outlined
      shaped
      type="info"
  >
    <p>{{imageUploadError.message}}</p>

    <p v-for="(e,index) in imageUploadError.errors.contract" :key="index">
      {{e}}
    </p>

  </v-alert>

</div>
</template>


<script>

import { mapGetters } from 'vuex'
import { FileTextIcon,Trash2Icon  } from "vue-feather-icons";
import MlLabel from "@/components/layout/form/MlLabel";

export default {
  name: 'contract',
  props: ['item'],
 components: {
   FileTextIcon,Trash2Icon,MlLabel
 },
  data () {
        return {
          loading: false,
          selectedFile: null,
          imageUploadError: null,
          imageUpload:false,
          file: null,
        }
  },
  
  computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
  },
  methods: {
    onFileSelected(event) {
      console.log(event);
      if (event == null) return;
      //this.selectedFile = event.target.files[0];
      this.selectedFile = event;
      this.onUpload();
    },
    onUpload() {
      this.imageUpload = true;
      this.imageUploadError = null;
      const formData = new FormData();
      formData.append('contract', this.selectedFile, this.selectedFile.name);
      let uri = '/api/vendor/bookings/'+this.item.id+'/contract';
      this.$http.post(uri, formData, {
        onUploadProgress: uploadEvent => {
          console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
        }
      })
          .then((response) => {
            this.item.contracts = response.data.contracts;
            this.$toast.success(response.data.message, "Success")
            this.file = null;
            this.imageUpload = false;
          }).catch((error) => {
        this.imageUpload = false;
        this.imageUploadError = error.data;
        console.log('Error: ', error.data);
      });
    },
    destroyImage(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.onImageDelete(vId);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    onImageDelete(id) {
      if (!this.item.id) return;
      let uri = '/api/vendor/bookings/' + this.item.id + '/contract/' + id;
      this.$http.delete(uri)
          .then((response) => {
            this.item.contracts = response.data.contracts;
            this.$toast.success(response.data.message, "Success");
          });
    },
}

}

</script>