<template>
<div>

  <v-dialog
      v-model="showPopUp"
      hide-overlay
      max-width="600px"
      :evalation="0"
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon dark @click="showPopUp=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          <slot name="title">
            {{title}}
          </slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div class="tw-py-2 tw-px-2">
        <slot name="description">
          <span class="tw-text-black tw-text-sm" v-html="description"/>
        </slot>
      </div>
    </v-card>
  </v-dialog>

  <help-circle-icon @click.prevent="showHelp($event)" class="tw-w-5 tw-h-5 tw-ml-2 tw-cursor-pointer" stroke-width="2" />

</div>
</template>

<script>
import { HelpCircleIcon } from "vue-feather-icons";

export default {
  name: "MLPopUp",
  props: {
    title: {
      default: '',
      type: String
    },
    description: {
      default: '',
      type: String
    }
  },
  components:{
    HelpCircleIcon
  },
  data(){
    return{
      showPopUp:false
    }
  },
  methods:{
    showHelp(e){
      if(e && e.stopPropagation) e.stopPropagation();
      this.showPopUp=!this.showPopUp;
    }
  }
}
</script>
