<template>
  <div
      @click="isClickable && $emit('click')"
      class="tw-rounded-full tw-h-8 tw-no-underline tw-leading-4 tw-px-3 tw-py-2 tw-font-semibold tw-flex tw-items-center tw-text-center"
      :class="classes"
  >
    <span>{{label}}</span>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    isClickable: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    classes: function () {
      return (this.label.startsWith("Accepted") || this.label.startsWith("Confirmed") || this.label.startsWith("Approved")) ? 'tw-bg-green-100 tw-text-green' :
          (this.label.startsWith("Rejected")) ? 'tw-bg-red-rejected tw-text-red-dark' :
              (this.label.startsWith("Expired")) ? 'tw-bg-gray-n1 tw-text-black' :
                'tw-bg-antiquewhite tw-text-antiquewhite-100'
    },
  }
}
</script>