
<template>
  <div class="tw-flex tw-h-full tw-flex-col tw-overflow-y-scroll tw-bg-white tw-py-8 tw-shadow-xl">
    <div class="tw-px-4 sm:tw-px-8 tw-pb-8 tw-border-b tw-border-gray-n1">
      <div class="tw-flex tw-items-start tw-justify-between">
        <h2 class="tw-text-lg tw-font-medium tw-text-black tw-font-semibold tw-flex tw-items-center"
            id="slide-over-title">
          {{$t('booking.name')}} #{{item.id}}
          <span class="tw-ml-4 tw-bg-green-100 tw-text-green tw-text-xs tw-font-semibold tw-inline-flex tw-py-2 tw-px-3 tw-rounded-3xl">{{item.status.name}}</span>
        </h2>
        <slot name="button"></slot>
      </div>
    </div>

    <div id="printMe" class="tw-relative tw-mt-6 tw-flex-1 tw-px-4 sm:tw-px-8">

      <div>
        <div class="tw-flex tw-justify-between tw-items-center">
          

          <button
              @click="print"
              class="tw-cursor-pointer tw-flex tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-solid tw-border-black tw-text-base tw-font-semibold tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4"
          >
            <printer-icon class="tw-w-5 tw-h-5 tw-mr-3" stroke-width="1.5" />
            {{$t('booking.print')}}
          </button>
          <v-btn
              small
              v-if="!item.pfc && this.$route.query.signature && 1==2"
              color="lime darken-1"
              class="ml-2 routerLink"
              dark
              :to="{ name: 'payment.order', params: { id: item.id }, query: {signature: this.$route.query.signature} }"
          >
            <v-icon left>
              mdi-currency-usd
            </v-icon>
            {{$t('cart.place_payment')}}
          </v-btn>
        </div>

        <div class="tw-grid sm:tw-grid-cols-2 tw-gap-x-8 tw-text-sm tw-mt-2 tw-text-gray-n3">
          <div class="tw-col-span-1 tw-space-y-2">
            <div>{{$t('booking.provided_by')}}: <span class="tw-text-red tw-text-semibold">{{$appName}}</span></div>
            <div>{{$t('booking.id')}}: <span class="tw-text-black">{{item.id}}</span></div>
            <div>{{$t('booking.issued')}}: <span class="tw-text-black">{{item.created_at}}</span></div>
            <div>{{$t('booking.status')}}: <span class="tw-text-black">{{item.status.name}}</span></div>


            <div v-if="item.paymentMethod">
              <span>{{$t('booking.payment_option')}}: <span class="tw-text-black">{{item.paymentMethod.name}}</span></span>
            </div>
            <div class="tw-hidden">
              <span>{{$t('booking.booked_dates')}}: </span>
              <span class="ml-1 tw-text-black" v-for="(d,index) in item.booked_dates" :key="index">
                {{dateFormat(d.datef)}}
              </span>
            </div>
          </div>
          <div class="tw-col-span-1 tw-space-y-2">
            <div>{{$t('booking.to')}}: <span class="tw-text-black">{{item.user.name}}</span></div>
            <div v-if="item.address">
              <div>{{$t('booking.address1')}}: <span class="tw-text-black">{{item.address.address1}}</span></div>
              <div v-if="item.address.address2">{{$t('booking.address2')}}: <span class="tw-text-black">{{item.address.address2}}</span></div>
              <div>{{$t('booking.location')}}: <span class="tw-text-black">{{item.address.state.name}}/{{item.address.city.name}}</span></div>
              <div>{{$t('booking.zip')}}: <span class="tw-text-black">{{item.address.postal_code}}</span></div>
              <div  v-if="item.address.phone">{{$t('booking.phone')}}: <span class="tw-text-black">{{item.address.phone}}</span></div>
              <div v-if="item.address.mobile_phone">{{$t('booking.mobile')}}: <span class="tw-text-black">{{item.address.mobile_phone}}</span></div>
              </div>
          </div>
        </div>

        <h2 class="tw-font-semibold tw-mt-6 tw-text-black tw-leading-relaxed tw-mb-3">Ordered Items</h2>
        <div class="tw-mt-4">
          <template v-for="(i,index) in item.items">
            <view-booking-item :item=i :key="index" />

            <!--
                <v-card :key="index">
                    <v-card-title>
                        #{{i.quantity}} {{$t('booking.piece')}}: {{i.name}}
                        <v-spacer/>
                        {{i.price | currency}}
                    </v-card-title>

                    <v-card-text>{{i.product.description}}</v-card-text>

                    <v-card-actions>
                        {{$t('booking.from')}}: {{i.product.vendor_name}}
                        <v-spacer/>
                                <div
                                class="text-xs"
                                    v-if="i.event">
                                    {{$t('booking.this')}} {{i.type.name}} {{$t('booking.is')}}
                                    <template v-for="(newlywed,index) in i.event.newlyweds">
                                    {{newlywed.first_name}} {{newlywed.last_name}}
                                    <template v-if="index == 0">
                                        &
                                    </template>
                                    </template>
                                    {{$t('booking.event')}}
                                </div>
                                <div v-else class="text-xs">
                                    {{i.type.name}}
                                </div>
                <v-spacer/>


                        <v-spacer/>
                        <div v-if="i.tax" class="mr-3">Tax {{i.tax_amount | currency}} ({{i.tax/100 | percent}})</div>
                        <b>Total {{i.total_price | currency}}</b>
                    </v-card-actions>

                    <v-divider/>
                </v-card>
            -->
          </template>

        </div>

        <div class="tw-text-right tw-my-4">
          <v-spacer/>
          {{$t('booking.total')}}: {{item.total | currency}} ({{$t('booking.tax')}} {{item.total_tax_amount | currency}})
          |
          <b>{{$t('booking.total_with_tax')}}: {{item.total_price_with_tax | currency}}</b>
        </div>

        <h2 class="tw-font-semibold tw-mt-6 tw-text-black tw-leading-relaxed tw-mb-3">{{$t('booking.payment_plan')}}</h2>
        <div>
          <div>
            <div>
              <table class="tw-border tw-text-sm tw-rounded-lg tw-border-gray-n1 tw-w-full tw-border-separate" style="border-spacing: 0">
                <tr class="tw-bg-gray-n1">
                  <th class="tw-py-3 tw-px-5">#</th>
                  <th class="tw-py-3 tw-px-5">{{$t('booking.amount')}}</th>
                  <th class="tw-py-3 tw-px-5">{{$t('booking.due_date')}}</th>
                  <th class="tw-py-3 tw-px-5">{{$t('booking.instalment_status')}}</th>
             
                </tr>
                <tbody>
                  <tr class="hover:tw-bg-gray-n0" v-for="(i,index) in item.paymentPlans" :key="index">
                    <td class="tw-py-3 tw-px-5">{{index+1}}</td>
                    <td class="tw-py-3 tw-px-5">{{i.charge/100 | currency}}</td>
                    <td class="tw-py-3 tw-px-5">{{dateFormat(i.due_date)}}</td>
                    <td class="tw-py-3 tw-px-5">{{i.proceeded ? 'PAID':'UNPAID'}}</td>
              
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <contract :item="item"/>

      </div>

    </div>
  </div>

</template>


<script>

import ViewBookingItem from './ViewBookingItem.vue';
import Contract from './Contract.vue';
import { PrinterIcon  } from "vue-feather-icons";

//import Gallery from "@/components/Gallery";

export default {
  middleware: ['auth','vendor'],
  props: ['item'],
  components:{
    ViewBookingItem,
    PrinterIcon,
    //Gallery,
    Contract
    //ImageIcon
  },
  data() {
      return {
          error: null,
          loading: false,
      }
  },
  computed: {
      paymanPlansWithTotal(){

        let total = [];
        for (let key in this.item.paymentPlans) {
            let t = this.item.paymentPlans[key].charge;
            t += total.map(item => item.total).reduce((prev, curr) => prev + curr, 0);
            let data = {
                "key": key,
                "total": this.item.paymentPlans[key].charge,
                "sum": t
            }
            total.push(data);

        }
        this.item.paymentPlans.forEach((element, index) => {
            element.total = total[index].sum ?? 0;
        });

        return this.item.paymentPlans;
      }
  },
  methods:{
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      print () {
      // Pass the element id here
      //this.$htmlToPaper('printMe');

        this.$http.get(
          '/api/orders/' + this.item.id + '/pdf', {
          responseType: 'blob'
        }).then((response) => {
          window.open(URL.createObjectURL(response.data), 'Invoice #' + this.item.id);
        }) .catch(function (error) {
        console.log(error);

        });
      }
  }
}
</script>







