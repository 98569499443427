<template>
  <div class="tw-flex tw-h-full tw-flex-col tw-overflow-y-scroll tw-bg-white tw-py-8 tw-shadow-xl">
    <div class="tw-px-4 sm:tw-px-8 tw-pb-8 tw-border-b tw-border-gray-n1">
      <div class="tw-flex tw-items-start tw-justify-between">
        <h2 class="tw-text-lg tw-font-medium tw-text-black tw-font-semibold tw-flex tw-items-center"
            id="slide-over-title">
          <file-text-icon class="tw-mr-3"/>
          Booking change request #{{order.id}}
        </h2>
        <slot name="button"></slot>
      </div>
    </div>
    <div class="tw-relative tw-mt-6 tw-flex-1 tw-px-4 sm:tw-px-8">
      <div>
        <h2 class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-3">General info</h2>

        <div class="tw-grid sm:tw-grid-cols-2 tw-gap-x-8 tw-text-sm tw-mt-2 tw-text-gray-n3">
          <div class="tw-col-span-1 tw-space-y-2">
            <div>{{$t('booking.id')}}: <span class="tw-text-black">{{order.id}}</span></div>
            <!--            <div>Revisions #: <span class="tw-text-black"></span></div>-->
          </div>
          <div class="tw-col-span-1 tw-space-y-2">
            <div v-if="user.vendor">Customer: <span class="tw-text-black">{{order.user.name}}</span></div>
            <div v-else>Provided by: <span class="tw-text-black">{{order.vendor.name}}</span></div>
            <!--            <div>Last time revised: <span class="tw-text-black">DATE</span></div>-->
          </div>
        </div>

        <!-- Message Box -->
        <div
             class="tw-text-sm tw-mt-6 tw-p-4 tw-border-solid tw-border-1 tw-border-gray-n1 tw-rounded"
             :class="background"
        >
          <span class="tw-mt-2 tw-flex tw-gap-3 tw-items-center">
            <div class="tw-rounded-full tw-overflow-hidden tw-h-10 tw-w-10">
              <img :src="order.user.avatar" :alt="order.user.name" class="tw-h-full tw-w-full tw-object-cover">
            </div>
            <p class="tw-m-0 tw-font-semibold">
              <span>{{order.user.name}}</span>
            </p>
          </span>
          <div class="tw-block tw-my-4">"{{order.change.request_description}}"</div>

          <!--- Vendor Msg -->
          <div
              v-if="order.change.vendor_confirmed"
              class="tw-text-sm tw-mt-6 tw-p-4 tw-border-solid tw-border-1 tw-border-gray-n2 tw-rounded"
              :class="background">

            <span class="tw-mt-2 tw-flex tw-gap-3 tw-items-center">
              <div class="tw-rounded-full tw-overflow-hidden tw-h-10 tw-w-10">
                <img :src="order.vendor.avatar" :alt="order.vendor.name" class="tw-h-full tw-w-full tw-object-cover">
              </div>
              <p class="tw-m-0 tw-font-semibold">
                <span>{{order.vendor.name}}</span>
              </p>
            </span>
            <div class="tw-block tw-my-4">"{{order.change.response_description}}"</div>

          </div>

        </div>

        <!-- Items Overview -->

        <!-- sta da pise useru kada je status == Requested -->

        <h2 class="tw-font-semibold tw-mt-6 tw-text-black tw-leading-relaxed tw-mb-3">
          <span v-if="user.vendor">Offer Adjustment</span>
          <span v-else>New Offer</span>
        </h2>

        <div class="tw-mt-4">

          <template v-for="(i,index) in itemPairs">
            <div class="tw-mt-4 tw-flex tw-justify-between tw-items-center" :key="index" >
              <div class="tw-flex">
                <div
                    v-if="i.item.product.type.name === 'Product'"
                    :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !i.item.product.image}"
                    class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4"
                    style="width: 160px; height: 100px">
                  <img v-if="i.item.product.image" :src="i.item.product.image"
                       class="tw-w-full tw-h-full tw-object-cover tw-rounded-lg" :alt="i.item.product.image.name"/>
                  <!--                  <gallery v-if="i.product.image" :thumb-only="true" :show-border="false"-->
                  <!--                           :images="[{src: i.product.image}]"-->
                  <!--                           :id="'offer-gallery' + i.product.id" :limit="1" thumbWrapperClass=""-->
                  <!--                           :gallery-setup="{}" default-image-class="tw-h-26"/>-->
                  <div v-else>
                    <image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/>
                  </div>
                </div>
                <div>
                  <div class="tw-leading-relaxed tw-font-semibold tw-text-black tw-mr-auto">
                    {{ i.item.name }}
                  </div>
                  <div class="tw-text-xs tw-text-gray-n3">
                    Original price: {{i.item.product.price | currency }}
                  </div>
                </div>
              </div>

              <!-- Price Overview -->

              <div v-if="user.vendor">
                <v-text-field
                    v-if="i.item.changeItem"
                    :disabled="order.change.vendor_confirmed"
                    solo
                    type="number" min="0" step="1" placeholder="USD"
                    @change="updateChange(order.change,order.items)"
                    v-model="i.item.changeItem.new_price" id="price" name="price" required
                    :label="i.item.changeItem.new_price"
                ></v-text-field>
                <v-text-field
                    v-else
                    :disabled="order.change.vendor_confirmed"
                    solo
                    type="number" min="0" step="1" placeholder="USD"
                    @change="updateChange(order.change,order.items)"
                    v-model="i.item.price" id="price" name="price" required
                    :label="i.item.price"
                ></v-text-field>
              </div>

<!--               Price for user -->

              <div v-else class="tw-text-right">
                <span v-if="i.change">
                  <span class="tw-text-sm tw-text-gray-n3">
                    {{i.change.new_price | currency }}
                  </span>
                  <br>
                  <span class="tw-text-red tw-text-xs tw-line-through">
                    {{i.change.old_price | currency }}
                  </span>
                </span>
              </div>
            </div>
          </template>
        </div>

        <!-- Payment Summary -->
        <h2 class="tw-font-semibold tw-mt-7 tw-text-black tw-leading-relaxed tw-mb-3">Payment Summary</h2>
        <div class="tw-mb-3 tw-flex tw-justify-between tw-text-sm">
          <span class="tw-text-gray-n3">Items Subtotal</span>
          {{order.total | currency}}
        </div>
        <div class="tw-mb-3 tw-flex tw-justify-between tw-text-sm">
          <span class="tw-text-gray-n3">Tax Amount</span>
          {{order.total_tax_amount | currency}}
        </div>
        <div class="tw-pt-3 tw-border-solid tw-border-0 tw-border-t-1 tw-border-gray-n1 tw-text-black tw-leading-relaxed tw-mb-3 tw-font-semibold tw-flex tw-justify-between">
          <h2>Total</h2>
          {{order.total_price_with_tax | currency}}
        </div>

        <span v-if="user.vendor">
          <h2 class="tw-font-semibold tw-mt-6 tw-text-black tw-leading-relaxed tw-mb-3">Message</h2>
          <ml-textarea :disabled="order.change.vendor_confirmed" v-model="order.change.response_description" id="description" name="description" required/>
        </span>

        <div class="tw-flex tw-mt-8 tw-gap-4">
          <Button
              type="s_dark"
              class="tw-w-full"
              v-if="!order.change.user_accepted && !order.change.user_rejected && !order.change.vendor_rejected"
              @click="rejectChange(order.change.id)"
          >
            <x-circle-icon/>
            Reject
          </Button>
          <Button
              type="s_dark"
              class="tw-w-full"
              v-if="user.vendor && !order.change.vendor_confirmed && !order.change.user_rejected && !order.change.vendor_rejected"
              @click="confirmChange(order.change,order.items)"
          >
            <check-circle-icon/>
            Confirm
          </Button>
          <Button
              type="s_dark"
              class="tw-w-full"
              v-else-if="!order.change.user_accepted && order.change.vendor_confirmed && !order.change.user_rejected && !order.change.vendor_rejected"
              @click="acceptChange(order.change.id)"
          >
            <check-circle-icon/>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImageIcon, FileTextIcon, XCircleIcon, CheckCircleIcon } from "vue-feather-icons";
import axios from "axios";
//import Gallery from "@/components/Gallery";
import MlTextarea from "@/components/layout/form/MlTextarea";
import Button from '@/components/Button'
import {mapGetters} from "vuex";
export default {
  middleware: ['auth','vendor'],
  props: ['order', 'actionHandler'],
  components:{
    //Gallery,
    ImageIcon,
    FileTextIcon,
    XCircleIcon,
    CheckCircleIcon,
    MlTextarea,
    Button
  },
  data() {
    return {
      error: null,
      // loading: false,
      products: this.order.items.filter(item => item.type.name != 'Package'),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    background: function(){
      return (this.user.vendor) ? 'tw-bg-red-100' : 'tw-bg-gray-n0';
    },
    // traversing kroz dva arraya jer kad je accepted ili rejected item.changeItem bude null a .change uvijek postoji
    itemPairs () {
      return this.order.change.items.map((itemChange, i) => {
        return {
          item: this.products[i],
          change: itemChange //this exists even when order is accepted or rejected.
        }
      })
    },

  },
  methods:{
    getBookings(){
      this.loading = true;
      let sortDesc = this.options.sortDesc[0];
      let orderby = sortDesc ? '-'+this.options.sortBy[0]:this.options.sortBy[0];
      const params =
          {
            "filter[search_data]" : this.search ? this.search:null,
            "filter[id]" : this.$route.query.id ? this.$route.query.id:null,
            "page": this.$route.query.page ? this.$route.query.page:this.options.page,
            "sort" : orderby ? orderby:this.$route.query.sort,
          };
      let uri = 'api/vendor/bookings';
      this.$http.get(uri,{ params: params }).then((response) => {
        this.data = response.data.data,
            this.meta = response.data.meta,
            this.loading = false
      });
    },
    updateChange(change,items){
      var datas = {
        "change" : change,
        "items" : items.filter(item => item.changeItem)
      }
      let uri = '/api/order-change/' + change.id;
      this.$http.post(uri,datas)
          .then((response) => {
            this.$toast.info(response.data.message, "Info", {timeout: 3000});
            this.getBookings();
          });
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    print () {
      // Pass the element id here
      //this.$htmlToPaper('printMe');
      axios.get(
          '/api/orders/' + this.order.id + '/pdf', {
            responseType: 'blob'
          }).then((response) => {
        window.open(URL.createObjectURL(response.data), 'Invoice #' + this.order.id);
      }) .catch(function (error) {
        console.log(error);
      });
    },
    rejectChange1(id){
      let uri = '/api/order-change/' + id + '/reject';
      this.$http.post(uri)
          .then((response) => {
            this.$toast.info(response.data.message, "Info", {timeout: 3000});
            this.actionHandler();
          });
    },
    rejectChange(item) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {
            this.rejectChange1(item);
            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }, true],
          ['<button>NO</button>', function (instance, toast) {
            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }],
        ]
      });
    },
    async confirmChange1(change,items){
      let uri = '/api/order-change/' + change.id + '/confirm';
      var datas = {
        "change" : change,
        "items" : items.filter(item => item.changeItem)
      }
      await this.$http.post(uri,datas)
          .then((response) => {
            this.$toast.info(response.data.message, "Info", {timeout: 5000});
            this.actionHandler();
          });
    },
    confirmChange(change,items) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {
            this.confirmChange1(change,items);
            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }, true],
          ['<button>NO</button>', function (instance, toast) {
            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          }],
        ]
      });
    },
    acceptChange(item) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.acceptChange1(item);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    acceptChange1(id){
      let uri = '/api/order-change/' + id + '/accept';
      this.$http.post(uri)
          .then((response) => {
            this.$toast.info(response.data.message, "Info", {timeout: 3000});
            this.actionHandler();
          });
    },
  }
}
</script>

