<template>
  <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-px-6 tw-py-4 tw-mb-1">

    <div class="tw-flex" >
      <div
          v-if="(item.product.type.name === 'Product' || item.product.type.name === 'Package')"
          :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !item.product.image}"
          class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4"
          style="flex-basis: 160px; height: 100px">
        <gallery v-if="item.product.image" :thumb-only="true" :show-border="false"
                 :images="[{src: item.product.image}]"
                 :id="'offer-gallery' + item.product.id" :limit="1" thumbWrapperClass=""
                 :gallery-setup="{}" default-image-class="tw-h-26"/>
        <div v-else>
          <image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/>
        </div>
      </div>
      <div class="tw-flex-grow tw-flex tw-flex-col">
        <div class="tw-flex tw-items-center">
          <div class="tw-leading-relaxed tw-font-semibold tw-text-black tw-mr-auto">
            {{ item.name }}
          </div>
        </div>
        <div class="tw-flex tw-items-center">
          <p class="tw-leading-relaxed tw-text-xs" v-html="item.description"/>
        </div>

        <div>
          <div class="tw-text-xs tw-text-gray-n2" v-if="item.product.type.name !== 'Package'">
            <span>
              {{ $t('vendor.offer.price') }}: {{item.price | currency }}
            </span>
            <span>
              | Tax {{ item.tax_amount | currency }}
            </span>
          </div>
          <div class="tw-text-xs tw-text-gray-n2" v-if="item.requested_dates.length > 0">
            <span>{{$t('booking.booked_dates')}}: </span>
            <span class="ml-1 tw-text-black" v-for="(d,index) in item.requested_dates" :key="index">
              {{dateFormat(d.datef)}}
            </span></div>
        </div>
        <div class="tw-ml-auto tw-mt-auto tw-font-semibold tw-leading-relaxed" v-if="item.product.type.name !== 'Package'">
          Total {{ item.total_price | currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Gallery from "@/components/Gallery";
import { ImageIcon } from "vue-feather-icons";

export default {
  name: 'BookingItem',
  props: ['item'],

  components: {
    //VendorAddons,
    //ProductsImageGallery,
    Gallery,
    ImageIcon,
  },

  watch: {

  },

  data() {
    return {
      loading: false,
      gallery: false
    }
  },

  computed: {

  },
  methods: {

  }

}
</script>
