
<template>
  <div>
    <div class="tw-grid tw-justify-center tw-items-center">
      <div class="tw-col-start-6 tw-p-2">

        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
            @click:clear="onClearClicked"
        ></v-text-field>

      </div>
      <div class="tw-col-start-1 tw-col-end-7">
        <v-data-table
            dense

            :expanded.sync="expanded"
            :headers="headers"
            :items="data"
            :search="search"
            :items-per-page="this.meta.per_page"
            :server-items-length="this.meta.total"
            :options.sync="options"
            class="ml-table"
            :loading="loading" loading-text="Loading... Please wait"
            :footer-props="{
              disableItemsPerPage : true,
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus'
            }"
        >

          <template v-slot:header.contract="{ header }">
            <div class="tw-flex">
              <span class="tw-flex">{{ header.text }}</span>
              <ml-pop-up  class="tw-w-3 tw-h-3 tw-flex-inline"
                          :title="dropFile.title"
                          :description="dropFile.description"
                          stroke-width="2" />
            </div>
          </template>
          <!-- moved to OrderChange component // show-expand parameter -->
          <template v-slot:expanded-item="{headers, item }">

            <td :colspan="headers.length">
              <table class="table text-xs">
                <tr>
                  <th>{{$t('cart.item_name')}}</th>
                  <th>{{$t('cart.price')}}</th>
                  <th>{{$t('cart.tax')}}</th>
                  <th>{{$t('cart.tax_amount')}}</th>
                  <th>{{$t('cart.total')}}</th>
                  <th>{{$t('order.new_price')}}</th>

                </tr>
                <tbody>

                <tr v-for="(i,index) in item.items.filter(item => item.type.name != 'Package')" :key="index">
                  <td>{{i.name}}</td>
                  <td>

                    <span>{{i.price | currency}}</span>

                  </td>
                  <td>{{i.tax/100 | percent}}</td>
                  <td>{{i.tax_amount | currency}}</td>
                  <td>{{i.total_price | currency}}</td>

                  <td>
                    <div v-if="i.changeItem && !item.change.user_accepted">
                      <v-text-field
                          :disabled="item.change.vendor_confirmed"
                          solo
                          type="number" min="0" step="1" placeholder="USD"
                          @change="updateChange(item.change,item.items)"
                          v-model="i.changeItem.new_price" id="price" name="price" required
                          :label="i.changeItem.new_price"
                      ></v-text-field>

                      <ml-error-msg :msg="errors['price']" />
                    </div>

                  </td>

                </tr>


                <tr>
                  <td :colspan="headers.length+1">
                    <div v-if="item.change">
                      <table class="table text-xs">
                        <tr>
                          <th>{{$t('order.request_description')}}</th>
                          <th>{{$t('order.my_response_description')}}</th>
                          <th colspan="3">{{$t('general.action')}}</th>

                        </tr>
                        <tbody>
                        <tr>
                          <td>{{item.change.request_description}}</td>
                          <td>
                            <v-textarea id="description" name="description" required
                                        :disabled="item.change.vendor_confirmed"
                                        v-model="item.change.response_description"
                                        @change="updateChange(item.change,item.items)"
                            >
                            </v-textarea>
                            <ml-error-msg :msg="errors['description']" />
                          </td>
                          <td colspan="3">

                            <v-btn  x-small color="red"
                                    v-if="!item.change.user_accepted && !item.change.user_rejected && !item.change.vendor_rejected"
                                    @click="rejectChange(item.change.id)">
                              <span>Reject</span>
                            </v-btn>

                            <v-btn  x-small color="green" class="ml-3"
                                    v-if="!item.change.vendor_confirmed && !item.change.user_rejected && !item.change.vendor_rejected"
                                    @click="confirmChange(item.change,item.items)">
                              <span>Confirm</span>
                            </v-btn>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>

                </tbody>
              </table>
            </td>

          </template>

          <template v-slot:[`item.id`]="{ item }">
            <span class="tw-flex-inline tw-ml-2">#{{item.id}}</span>
          </template>

          <template v-slot:[`item.price`]="{ item }">
            {{item.total  | currency}}
          </template>

          <template v-slot:[`item.total_tax_amount`]="{ item }">
            {{item.total_tax_amount | currency}}
          </template>

          <template v-slot:[`item.total`]="{ item }">
            {{item.total_price_with_tax | currency}}
          </template>

          <template v-slot:[`item.booked_dates`]="{ item }">

            <div v-for="(i,index) in item.booked_dates" :key="index">
              <!--
               {{dateFormat(i.date)}}
               {{formatDate(new Date(i.date).toISOString().substr(0, 10))}}
               -->
              {{dateFormat(i.datef)}}
            </div>
          </template>
          <!--
          <template v-slot:[`item.approval_status`]="{ item }">
            <span :style="item.approval_status == 'Pending' ? 'color:blue':null">{{item.approval_status}}</span>
          </template>
          -->



          <!--
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="item.status != 'Approved' && item.status != 'Rejected'"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            v-if="item.status != 'Approved' && item.status != 'Rejected'"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>

        </template>
        -->

          <template v-slot:[`item.view`]="{ item }">

            <!--
            <v-btn color="blue" dark
                x-small
                :to="{ name: 'vendor.bookings', params: {id: item.id}}"
                class="routerLink"
                target="_blank"
              >View booking</v-btn>
              -->

            <v-btn color="blue" dark
                   x-small
                   @click="print(item)"
            >View</v-btn>

          </template>

          <template v-slot:[`item.actions`]="{ item }">

            <!-- Create offer -->
            <v-chip :color="getColor(item.status)" dark
                    v-if="item.status === 'Open'"
                    @click="item.status === 'Open' ? null:null"
                    small
                    :to="{ name: 'vendor.product.create', query: { quote: item.id }}"

                    class="routerLink ma-2"
            >Create offer</v-chip>

            <!-- View offer -->
            <v-chip :color="getColor(item.status)" dark
                    v-if="item.status === 'Offered'"
                    @click="item.status === 'Offered' ? null:null"
                    small
                    :to="{ name: 'vendor.offers', query: { quote: item.id }}"

                    class="routerLink ma-2"
            >View offered package</v-chip>

            <!-- Approve Reject -->
            <div v-if="!item.is_approved && !item.rejected">

              <v-btn
                  rounded
                  color="green" dark
                  @click="update(item.id,true)"
                  small
                  class="routerLink ma-2"
                  :loading="loading">
                Approve
              </v-btn>

              <v-btn
                  rounded
                  color="red" dark
                  @click="update(item.id,false)"
                  small
                  class="routerLink ma-2"
                  :loading="loading">
                Reject
              </v-btn>

            </div>

          </template>


          <template v-slot:[`item.status`]="{ item }">
              <span class="text-xs font-weight-light red--text"
                    :class="getColorClass(item.status)">{{ item.status }}
              </span>
          </template>
          <template v-slot:[`item.payment`]="{item}">
            <div class="tw-p-1">
              <div v-if="item.transaction_amount == 0" class="tw-rounded-full tw-bg-gray-n1 tw-text-black tw-h-8 tw-no-underline
                tw-items-center tw-leading-4 tw-font-semibold tw-flex tw-justify-center">
                <span>Unpaid</span>
              </div>
              <div v-else-if="item.transaction_amount != 0 && !item.pfc"
                   class="tw-rounded-full tw-bg-antiquewhite
                  tw-text-antiquewhite-100 tw-h-8 tw-no-underline
                  tw-items-center tw-leading-4 tw-font-semibold tw-flex tw-justify-center">
                <span>Partially paid</span>
              </div>
              <div v-else-if="item.pfc" class="tw-rounded-full tw-bg-green-100 tw-text-green tw-h-8 tw-no-underline
               tw-leading-4 tw-px-8 tw-font-semibold tw-flex tw-items-center tw-justify-center">
                <span>Fully paid</span>
              </div>

              <div v-else class="tw-rounded-full tw-bg-gray-n2 tw-text-black tw-h-8 tw-no-underline
                tw-items-center tw-leading-4 tw-font-semibold tw-flex tw-justify-center">
                <span>Pending</span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.change_status`]="{ item }">

            <div class="tw-p-2">
              <div v-if="item.change">
                <OrderChangeLabel :label="item.change.status" :isClickable="labelClickable(item.change.status)" @click="changeOrder(item)"/>
              </div>
            </div>
          </template>

          <template v-slot:[`item.contract`]="{ item }">
            <div @click="contractView(item)" class="tw-flex tw-cursor-pointer">
              <file-text-icon  class="tw-flex tw-w-5 tw-h-5 tw-text-gray-n4" />
              <span class="tw-ml-1 tw-flex-inline">{{item.contracts.length}}</span>
            </div>
          </template>


        </v-data-table>
      </div>
    </div>
    <!-- View Order -->
    <v-dialog
        v-model="showPrint"
        fullscreen
        hide-overlay
        transition="slide-x-transition"
        origin="0 0"
    >

      <div class="tw-absolute tw-inset-0 tw-overflow-hidden">
        <div @click="showPrint = false" class="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-75 tw-transition-opacity" aria-hidden="true"></div>
        <div class="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-max-w-full tw-pl-10 sm:tw-pl-16">
          <div class="tw-pointer-events-auto tw-w-full tw-ml-auto" style="min-width: 704px;max-width: 704px;">
            <ViewBooking v-if="this.showPrint" :item=this.selectedItem >
              <template v-slot:button>
                <div class="tw-ml-3 tw-flex tw-h-7 tw-items-center">
                  <button @click="showPrint = false" type="button"
                          class="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2">
                    <span class="tw-sr-only">{{ $t('general.close') }}</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </button>
                </div>
              </template>
            </ViewBooking>
          </div>
        </div>
      </div>

    </v-dialog>

    <!-- View Contract -->
    <v-dialog
        v-model="showContract"
        hide-overlay
        max-width="600px"
    >
      <div class="tw-bg-white tw-p-10">
      <span class="tw-bg-white tw-flex tw-justify-between tw-items-center tw-mb-4">
            <span class="tw-text-xl tw-font-semibold tw-flex tw-gap-3.5 tw-items-center">
              <file-text-icon/>
              Your drop files & documents
            </span>
            <button @click="showContract = false" class="tw-cursor-pointer">
              <x-icon/>
            </button>
      </span>
        <contract :item="selectedItem"/>
      </div>

    </v-dialog>

    <!-- Order Change Modal -->

    <v-dialog
        v-model="orderChange"
        fullscreen
        hide-overlay
        transition="slide-x-transition"
        origin="0 0"
    >
      <div class="tw-absolute tw-inset-0 tw-overflow-hidden">
        <div @click="orderChange = false" class="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-75 tw-transition-opacity" aria-hidden="true"></div>
        <div class="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-max-w-full tw-pl-10 sm:tw-pl-16">
          <div class="tw-pointer-events-auto tw-w-full tw-ml-auto" style="min-width: 704px;max-width: 704px;">
            <OrderChange v-if="this.orderChange" :order=this.selectedItem :actionHandler="changeConfirmedRejected">
              <template v-slot:button>
                <div class="tw-ml-3 tw-flex tw-h-7 tw-items-center">
                  <button @click="orderChange = false" type="button"
                          class="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2">
                    <span class="tw-sr-only">{{ $t('general.close') }}</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </button>
                </div>
              </template>
            </OrderChange>
          </div>
        </div>
      </div>
    </v-dialog>


  </div>
</template>


<script>

import ViewBooking from '../../../components/ViewBooking.vue'
//import MlInputText from "@/components/layout/form/MlInputText";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
//import { InfoIcon ,CheckIcon    } from "vue-feather-icons";
import i18n from '@/i18n'
import Contract from '@/components/Contract.vue';
import { FileTextIcon, XIcon  } from "vue-feather-icons";
import MlPopUp from "@/components/help/MLPopUp";
import OrderChange from '@/components/OrderChange'
import OrderChangeLabel from "@/components/OrderChangeLabel";

//import MlTextarea from "@/components/layout/form/MlTextarea";
export default {
  middleware: ['auth','vendor'],
  components:{
    ViewBooking,MlErrorMsg,Contract,FileTextIcon,MlPopUp, XIcon,
    OrderChange,
    OrderChangeLabel,
  },
  metaInfo() {
    return {
      title: i18n.t('dashboard.menu.bookings'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendor/bookings'),
        },
      ]
    }
  },
  data () {
    return {
      orderChange: false,
      dropFile: {
        title: "Couple Documents",
        description: "Documents for Couples to review and accept."
      },
      showPrint: false,
      showContract: false,
      selectedItem: null,
      singleExpand: false,
      expanded: [],
      response: [],
      errors:[],
      dialog: false,
      editedIndex: -1,
      editedItem: {
        status: ''
      },
      defaultItem: {
        status: ''
      },
      loading: false,
      data: [],
      meta: {
        total:0
      },

      search: '',
      options: {
        itemsPerPage: 32
      },
      headers: [

        { text: 'View', value: 'view',sortable: false, },
        {text: 'Order ID', value: 'id', sortable: true},
        { text: 'Request Date', value: 'created_at' },
        { text: 'Requested by', value: 'user.name',sortable: false },
        /*
        { text: 'Price', value: 'price' },
        { text: 'Tax Amount', value: 'total_tax_amount' },
        */
        { text: 'Total Price', value: 'total' },
        { text: 'Date Booked', value: 'booked_dates',sortable: false },
        {text: 'Order Status', value: 'status.name', sortable: true},
        { text: 'Payment', value: 'payment',sortable: false },
        { text: 'Order Change Status', value: 'change_status',sortable: false },
        // { text: 'Delivery status', value: 'status.name',sortable: false },
        { text: 'Selection Required', value: 'actions',sortable: false },
        { text: 'File Uploads', value: 'contract',sortable: false }
      ],
    }
  },
  mounted() {
    //if(this.$route.query.id) this.search = this.$route.query.id;
    // this.getBookings();
  },
  watch: {
    options: {
      async  handler () {
        await this.getBookings()
      },
      deep: true,
    },
    meta: function(){
      //this.options.page = this.meta.current_page;
      //this.options.itemsPerPage = this.meta.per_page;
      ///:items-per-page="meta.per_page"
    },
    search: function(){
      if(this.search == null || this.search == "") {
        this.getQuotes();
      }
      if (this.search.length < 3) return
      this.getBookings()
    }
  },
  methods: {
    labelClickable(label){
      if(label === "Accepted" || label === "Confirmed" || label === "Requested")
        return true;
      return false;
    },
    changeConfirmedRejected(){
      this.orderChange = false;
      this.getBookings();
    },
    changeOrder(item){
      this.selectedItem = item;
      this.orderChange = true;
      console.log(item);
    },
    openClose(item){

      var index = this.expanded.indexOf(item);
      console.log(index);
      if(index == -1){
        this.expanded.push(item);
        //this.expanded.concat(item);
      }else{
        this.expanded.splice(index, 1);
      }

    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    onClearClicked () {
      this.search = null;
      this.getBookings();
    },
    print(item){
      //console.log(item);
      this.selectedItem = item;
      this.showPrint = true;
    },
    contractView(item){
      this.selectedItem = item;
      this.showContract = true;
    },
    getColor (satus) {
      if (satus === 'Open') return 'cyan'
      else if (satus === 'Offered') return 'orange'
      else if (satus === 'Accepted') return 'green'
      else return 'red'
    },
    getColorClass (satus) {
      if (satus === 'Open') return 'yellow--text'
      else if (satus === 'Approved') return 'black--text'
      else if (satus === 'Pending') return 'orange--text'
      else if (satus === 'Processed') return 'blue--text'
      else if (satus === 'Delivered') return 'green--text'
      else return 'red--text'
    },
    rejectChange1(id){
      let uri = '/api/order-change/' + id + '/reject';
      this.$http.post(uri)
          .then((response) => {
            this.$toast.info(response.data.message, "Info", {timeout: 3000});
            this.getBookings();
          });
    },
    rejectChange(item) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.rejectChange1(item);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    async confirmChange1(change,items){

      let uri = '/api/order-change/' + change.id + '/confirm';
      var datas = {
        "change" : change,
        "items" : items.filter(item => item.changeItem)
      }
      await this.$http.post(uri,datas)
          .then((response) => {
            this.$toast.info(response.data.message, "Info", {timeout: 5000});
            this.getBookings();
          });
    },
    confirmChange(change,items) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.confirmChange1(change,items);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    updateChange(change,items){
      var datas = {
        "change" : change,
        "items" : items.filter(item => item.changeItem)
      }
      let uri = '/api/order-change/' + change.id;
      this.$http.post(uri,datas)
          .then((response) => {
            this.$toast.info(response.data.message, "Info", {timeout: 3000});
            this.getBookings();
          });
    },
    getBookings(){
      this.loading = true;

      let sortDesc = this.options.sortDesc[0];
      let orderby = sortDesc ? '-'+this.options.sortBy[0]:this.options.sortBy[0];


      const params =
          {
            "filter[search_data]" : this.search ? this.search:null,
            "filter[id]" : this.$route.query.id ? this.$route.query.id:null,
            "page": this.$route.query.page ? this.$route.query.page:this.options.page,
            "sort" : orderby ? orderby:this.$route.query.sort,
          };

      let uri = 'api/vendor/bookings';
      this.$http.get(uri,{ params: params }).then((response) => {
        this.data = response.data.data,
            this.meta = response.data.meta,
            this.loading = false
      });
    },
    authorize(id,authorize){

      this.loading = true;
      let item = {
        authorize: authorize
      };
      let uri = '/api/vendor/bookings/' + id + '/authorize';
      this.$http.patch(uri,item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success")
          }).finally(() =>
          this.getBookings()
      )
    },
    update(id,authorize) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.authorize(id,authorize);

            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

          }],
        ]
      });
    },
  },

}
</script>








